@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');


.navs-tab-list {
    background: #FFFFFF !important;
    border-radius: 6px 6px 0px 0px !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    white-space: nowrap;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px 30px; */
    text-align: center !important;
    color: #5E5873 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: revert !important;
    size: revert !important;
    margin-top: 'revert' !important;
    margin-bottom: 'revert' !important;
}

.export-button {
    background: #FFFFFF !important;
    border: 1px solid #4E924D !important;
    border-radius: 5px !important;
    color: #4E924D !important;
    font-weight: 600 !important;
    font-family: 'Montserrat', sans-serif !important;
}

.refresh-btn {
    background: #FFFFFF !important;
    border: 1px solid #FD7E14 !important;
    border-radius: 5px !important;
    color: #FD7E14 !important;
    font-weight: 600 !important;
    font-family: 'Montserrat', sans-serif !important;
}

.date-range-picker {
    background: transparent !important;
    height: 38px !important;
    border-radius: 5px !important;
    /* border: 1px solid #D8D6DE !important; */
    color: #6E6B7B !important;
    /* z-index: 9999 !important; */
}

.heading_style {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    color: #5E5873 !important;
}

.react-calendar {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid #FFFFFF !important;
    padding: 5px !important;
}

.react-calendar__tile--active {
    background: #4E924D !important;
}

.spent-txt-gre {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    color: #28C76F !important;

}

html .content.app-content .content-area-wrapper {
    display: inline !important;
    position: relative !important;
    overflow: hidden !important;
}

.daily-bgt {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    color: #6E6B7B !important;
}

.user-log-text {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    color: #6E6B7B !important;
}

.daily-bgt-1 {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    color: #28C76F !important;
}

.running-btn {
    background: rgba(40, 199, 111, 0.12);
    border-radius: 17px !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 10px !important;
    padding: 3px 10px 3px 10px !important;
    color: #28C76F !important;
}

.user-name-tb {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-decoration-color: #6E6B7B !important;
    color: #5E5873 !important;

}

.user-full-name-tb {
    font-family: 'Montserrat';
    font-style: 'bold';
    font-size: 14px !important;
    font-weight: 700;
    text-decoration-color: #6E6B7B !important;
    color: #5E5873 !important;
    white-space: pre-wrap;
}

.user-full-name-tab {
    font-family: 'Montserrat';
    font-style: 'bold';
    /* line-height: 18px; */
    font-weight: 700;
    /* text-decoration: underline; */
    text-decoration-color: #6E6B7B !important;
    color: #5E5873 !important;
    white-space: pre-wrap;
}

.ad-heading {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: #6E6B7B;
}

.user-full-name-tb:hover {
    font-family: 'Montserrat';
    font-style: 'bold';
    font-size: 14px !important;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-color: #4E924D !important;
    color: #4E924D !important;
    white-space: pre-wrap;
}

.user-full-name-tab:hover {
    font-family: 'Montserrat';
    font-style: 'bold';
    /* line-height: 18px; */
    font-weight: 700;
    text-decoration: underline;
    text-decoration-color: #4E924D !important;
    color: #4E924D !important;
    white-space: pre-wrap;
}

.cpc-btn {
    background: #BABFC7;
    border-radius: 9px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    padding: 2px 4px 2px 4px;
    font-size: 12px;
    color: #FFFFFF;

}

.pausd-btn {
    background: rgba(255, 159, 67, 0.12);
    border-radius: 17px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 11px !important;
    text-align: center;
    padding: 3px 10px 3px 10px !important;
    color: #FF9F43;

}

.draph-btn {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #00CFE8;
    padding: 3px 10px 3px 10px !important;
    background: rgba(0, 207, 232, 0.12);
    border-radius: 17px;

}

.light_gray_color {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 13px !important;
    color: #6E6B7B !important;

}

.light_blue_color {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    text-decoration-line: underline !important;
    color: #0D6EFD !important;
}

.react-daterange-picker__button svg {
    color: #E0E0E0;
}

p {
    margin: 0px !important;
}

.badge i,
.badge svg {
    height: 12px !important;
    width: 12px !important;
    font-size: 12px;
    stroke-width: 3;
    vertical-align: top;
}

.langauge_name:hover {
    text-decoration: underline;
    text-decoration-color: '#32C526';

}

/* .react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
    padding: 0px !important;
} */

.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.biz-profile {
    margin-top: -14%;
}

.read-more-less--more,
.read-more-less--less {
    color: #FF9F43;
    font-weight: 500;
    text-decoration: none !important;
    font-size: 14px !important;
}

.read-more-lessPost--more,
.read-more-lessPost--less {
    color: #4E924D;
    font-weight: 500;
    text-decoration: none !important;
    font-size: 14px !important;
}


.report_managment {
    background: #EEEEEE !important;
    border-radius: 6px !important;
    color: #6E6B7B !important;

}

.report_managment-2 {
    background: #F6F6F6 !important;
    border-radius: 6px !important;
    color: #6E6B7B !important;

}

.responsive-css-sm {
    display: none;
}

.header_border {
    background: #FFFFFF !important;
    border: 2px solid #EEEEEE !important;
    border-radius: 10px !important;
}

.profile_text {
    position: absolute;
    text-overflow: ellipsis !important;
    margin-left: 200px !important;
    margin-top: -30px !important;
    color: #FFFFFF !important;
    font-weight: bold !important;

}

.tf_12 {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #5E5873 !important;
}

.card-imgs-overlay {
    position: absolute !important;
    bottom: 0 !important;
}

.pro_container {
    position: relative !important;
}

.biz-bg {
    position: absolute !important;
    right: 0 !important;
    color: #FFFFFF !important;
    bottom: 0 !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
    border: 2px solid #FFFFFF !important;

}

.lightColor {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    color: #B5B5C3;

}

.success_btn {
    border-color: #4CA171 !important;
    background-color: #4CA171 !important;
    border: none !important;
    outline: none !important;

}

.top_text_gray {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #82868B !important;

}

.light_color_post {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-align: right !important;
    color: #80C4C0 !important;

}

.Ranking_name {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    color: #82868B !important;

}

.img_shotz {
    position: relative !important;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centereded {
    left: 30%;
    transform: translate(-117%, -6%);
}

.postitionForBox {
    position: absolute;
}

.postitionForBox .lulu {
    position: absolute;
    transform: translate(24%, -119%);
}

.postitionForBox .Paidcasticon {
    position: absolute;
    transform: translate(-7%, -100%);
}


.bottomAline {
    position: absolute;
    width: 100%;
    bottom: 0px !important;
    font-size: 18px;
}

.right_img {
    position: absolute;
    bottom: -8px !important;
    right: -8px !important;
    outline: none !important;
    border: none !important;
}

.right_imge {
    position: absolute;
    bottom: -1px !important;
    right: 1px !important;
    outline: none !important;
    border: none !important;
}

.activeGoLive {
    background-color: rgba(243, 237, 237, 0.048);
    position: absolute;
    top: 5% !important;
    right: 85% !important;
    outline: none !important;
    border: none !important;
}

.ActiveGoliveFrontView {
    position: absolute;
    top: auto;
    bottom: 19px;
}

.stickermgmt {
    position: absolute;
    top: 0px;
}

/* ---------custome Switch----------- */
.custom-control {
    position: relative;
    z-index: revert !important;
    display: block;
    min-height: 1.45rem;
}

.stickermgmttitlecolour {
    background-color: #4e924d28;
    position: absolute;
    width: 100%;
    bottom: 15%;
}

.removeButton {
    position: absolute;
    top: -16px;
    right: -18px;
    background-color: aliceblue;
}

.uploadButton {
    display: inline-block;
    background-color: #4e924d7c;
    /* width: min-content; */
    color: white;
    padding: 0.5rem;
    font-family: sans-serif;
    border-radius: 0.3rem;
    cursor: pointer;
    /* margin-top: 1rem; */
}

.golive {
    background-color: #FFFFFF !important;
    position: absolute !important;
    top: -5% !important;
    align-items: center !important;
    left: 5% !important;
    padding: 2px;
    border-radius: 5px !important;
    font-weight: 900 !important;
    outline: none !important;
    border: none !important;
}


.date_range_style {
    background: #FFFFFF !important;
    border-radius: 5px !important;
    padding: 5px !important;
    border: 1px solid #D8D6DE !important;
}

.reactReadMoreReadLess {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    text-decoration-line: underline !important;
    /* color: #5E5873 !important; */
    cursor: pointer;
}

.image-upload>input {
    display: none !important;
}

.reactReadMoreReadLessPost {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    cursor: pointer;
}

.pogress-mark-style {
    margin-left: -10% !important;
    align-items: center !important;
    align-self: center !important;
}

.reactReadMoreReadLess {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    text-decoration-line: underline !important;
    color: #2F80ED !important;
    cursor: pointer;
}

.treadpost { 
    background-color: #FF822E !important;
    padding: 2px !important;
    width: 130px !important;
    border-radius: 0px 8px 0px 8px !important;
    text-align: center !important;
    text-decoration: none !important;
}
.treadposts { 
    background-color: #FF822E !important;
    padding: 2px !important;
    width: 110px !important;
    border-radius: 20px 0px 0px 20px !important;
    text-align: center !important;
    text-decoration: none !important;
}

.css-47z9mc-multiValue {
    background-color: #E3F4EA !important;
    color: #5E5873 !important;
    /* padding: 5px !important;
    border-radius: 25px !important; */
}

.select__multi-value .select__multi-value__label,
.react-select__multi-value .select__multi-value__label {
    color: #5E5873 !important;
    font-size: 0.85rem !important;
    font-weight: 800 !important;
}

/* .css-1eyvi4t-control {
    border: none !important;
    outline: none !important;
}

.css-1eyvi4t-control:active {
    border: none !important;
    outline: none !important;
} */

input[type='radio']:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -2px;
    left: -3px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 20px !important;
    height: 20px !important;
    position: relative;
    background-color: #81C14B;
    display: inline-block;
    visibility: visible;
}

.text_dark_light_com {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #5E5873 !important;
}

.text_light_com {
    font-family: 'Montserrat' !important;
    font-style: italic !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    color: #5E5873 !important;
}

/* -------------------Sourabh CSS------------------------------------------ */

.mabox {
    margin-left: 24px !important;
}

.mabox1 {
    margin-left: 28px !important;
}

.countr_style {
    background: #FFFFFF;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    z-index: -1 !important;
}

.pd-1 {
    border: 1px solid #D8D6DE;
    border-radius: 6px;
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
    color: #5E5873;
    width: 117px;
}

.editName {
    margin-top: -20px !important;
    right: 0 !important;
}

.cardbody {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: transparent;
    padding-top: 20px;
}

.cardrow {
    display: grid;
    grid-template-columns: 80px;
    grid-template-rows: 79px;
    grid-template-areas: "image";
    font-family: roboto;
    border-radius: 5px;
    box-shadow: -1rem 0 3rem transparent;
    text-align: center;
    overflow: hidden;
    transition: all 0.6s ease;
}

.cardrow-img {
    grid-area: image;
    background: url("https://avatarfiles.alphacoders.com/149/149714.jpg");
    background-size: cover !important;

}

/* announcement css */

.annc_cont {
    position: relative !important;
    display: flex;
}

.left_content {
    position: absolute !important;
    width: 25% !important;
    left: 0 !important;
    bottom: 0 !important;
    height: 100vh !important;
    overflow-y: scroll;
    z-index: 100 !important;
}

.right_content {
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    width: 74% !important;
    height: 100vh !important;
    overflow-y: scroll;
    z-index: 100 !important;
}

@media (max-width: 1300px) {
    .left_content {
        left: 0 !important;
        width: 34% !important;
        height: 100vh !important;
        overflow-y: scroll;
    }

    .right_content {
        width: 74% !important;
        position: fixed !important;
        right: 0 !important;
        height: 100vh !important;
        overflow-y: scroll;
    }

}

.mobile {
    /* position: relative; */
    background-image: url('../adManager//Ad\ Performance\ Overview/mob2.svg') !important;
    /* padding: '10px' !important; */
    background-size: cover !important;
    background-repeat: no-repeat !important;
    /* width: 100%; */
    padding-bottom: 53% !important;
}

.Ad {
    margin: '10px' !important;
    height: "100%";
}

.v-ideo {
    object-fit: cover;
    height: "100%";
    position: relative;
    top: 0;
    left: 0;
}

@media only screen and (max-width: 100px) {
    .mobile {
        /* position: relative; */
        background-image: url('../adManager//Ad\ Performance\ Overview/mob2.svg') !important;
        /* padding: '10px' !important; */
        background-size: cover !important;
        background-repeat: repeat-y;
        height: 100%;
        padding-bottom: 50% !important;
    }

    .Ad {
        margin: '10px' !important;
    }
}

.filters_container {
    background-color: #FFFFFF;
    border: '2px solid #757575';
    border-radius: '0px';
}

.tp_fill_container {
    display: block !important;
}

/* .topUserFilters {
    width: 500px !important;
} */

.topcountryFilters {
    direction: ltr !important;
}


.cardrow:hover {
    transform: translateY(-20px);
}

.cardrow:hover~.card {
    transform: translateX(20px);
}

.cardrow:not(:first-child) {
    margin-left: -20px;
}

#sb {
    background-position: bottom;
    background-image: url("https://media1.popsugar-assets.com/files/thumbor/HwtAUAufmAZv-FgGEIMJS2eQM-A/0x1:2771x2772/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/03/30/878/n/1922398/eb11f12e5e825104ca01c1.02079643_/i/Robert-Downey-Jr.jpg")
}

#yosemite {
    background-image: url("https://avatarfiles.alphacoders.com/149/149714.jpg")
}

#marin {
    background-image: url("https://cdn.britannica.com/70/129770-050-AEBE1EB9/Robert-Downey-Jr-2008.jpg")
}

.lable_style {
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 14px !important;
    cursor: pointer !important;
    text-transform: capitalize !important;

}

.dropmenu_style {
    min-width: 600px !important;

}

.dropmenu_style2 {
    min-width: 300px !important;

}

.nav_tabs_Top_trends {
    overflow-x: auto !important;
    overflow-y: hidden !important;
    flex-wrap: nowrap !important;

}

#style-15::-webkit-scrollbar-track {
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #4E924D;
    /* border-radius: 5px; */
}

#style-15::-webkit-scrollbar {
    height: 5px !important;
    background-color: #F5F5F5;
}

#style-15::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #eaefec !important;
}

.country_filters {
    height: 550px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;

}

.about_filters {
    height: 400px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;

}

/* .scrollbar_tab {
    margin-left: 30px;
    float: left;
    height: 300px;
    width: 65px;
    background: #F5F5F5;
    overflow-y: scroll;
    margin-bottom: 25px;
}

#scrollBarHorizantal::-webkit-scrollbar-track {
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #F5F5F5 !important;
}

#scrollBarHorizantal::-webkit-scrollbar {
    height: 10px !important;
    background-color: #F5F5F5;
}

#scrollBarHorizantal::-webkit-scrollbar-thumb {
    background-color: #000000 !important;
    border: 2px solid #555555 !important;
} */

/* 9 july */

/* .nav-pills .nav-link.active {
    border-color: #4CA171;
    box-shadow: 0 4px 18px -4px rgb(76 161 113 / 65%);
    border-radius: 5px 5px 0px 0px !important;
} */

/* 21 june */

.modern-radio-container {
    margin: 24px 0;
    display: flex;
    cursor: pointer;
    user-select: none;
}

.radio-outer-circle {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border: 2px solid #0d5bc6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    transition: all 0.1s linear;
}

.simple-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
}

.Swiper-img-Gallery {
    background-size: cover !important;
    overflow: hidden !important;
}

.poll-height {
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 37px !important;
    width: 60px !important;
}

.radio-inner-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #0d5bc6;
    transition: all 0.1s linear;
}

div#swal2-content {
    padding: 18px !important;
}

.unselected {
    border: 2px solid #666;
}

.unselected-circle {
    width: 0;
    height: 0;
}

.helper-text {
    color: #666;
    padding-right: 8px;
}

.MuiAccordionDetails-root {
    display: revert !important;
    padding: revert !important;
}

.container_img {
    position: relative;
    text-align: center;
    color: white;
}

.top-right {
    position: absolute !important;
    top: 4px !important;
    font-weight: 900 !important;
    right: 8px !important;
}


/* User Profile Css */

.slider_card {
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
    height: 100% !important;
}

.rgb::after {
    content: "";
    background: linear-gradient(45deg,
            #ff0000 0%,
            #ff9a00 10%,
            #d0de21 20%,
            #4fdc4a 30%,
            #3fdad8 40%,
            #2fc9e2 50%,
            #1c7fee 60%,
            #5f15f2 70%,
            #ba0cf8 80%,
            #fb07d9 90%,
            #ff0000 100%) repeat 0% 0% / 300% 100%;
    position: absolute;
    inset: -3px;
}

@keyframes rgb {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.rgb::after {
    border-radius: 16px;
    filter: blur(8px);
    transform: translateZ(-1px);
    animation: rgb 6s linear infinite;
}

@media (max-width: 1210px) {
    .responsive-css {
        display: none !important;
        visibility: hidden !important;
    }

    .responsive-css-sm {
        display: contents !important;

    }

}